<!-- 修改企业信息 -->
<template>
  <div class="message">
    <el-card style="border-radius: 10px">
      <h3>企业信息：</h3>
      <div style="display: flex; justify-content: center">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="180px"
          class="demo-ruleForm"
        >
          <el-form-item label="企业名称：">
            <el-input v-model="ruleForm.firmName" disabled></el-input>
          </el-form-item>
          <el-form-item label="统一社会信息代码：">
            <el-input v-model="ruleForm.socialCode" disabled></el-input>
          </el-form-item>
          <el-form-item label="企业地址：" prop="area">
            <el-input
              v-model="ruleForm.area"
              placeholder="请输入企业地址"
              :disabled="this.forbidden"
            ></el-input>
          </el-form-item>
          <el-form-item label="法人名称：" prop="legal">
            <el-input
              v-model="ruleForm.legal"
              placeholder="请输入法人名称"
              :disabled="this.forbidden"
            ></el-input>
          </el-form-item>
          <el-form-item label="法人身份证号：" prop="id">
            <el-input
              v-model="ruleForm.id"
              placeholder="请输入法人身份证号"
              :disabled="this.forbidden"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业基本户账号：" prop="account">
            <el-input
              v-model="ruleForm.account"
              placeholder="请填写正确的企业基本户账号"
              oninput="value=value.replace(/[^\d]/g,'')"
              :disabled="this.forbidden"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业基本户开户行：" prop="bank">
            <el-input
              v-model="ruleForm.bank"
              placeholder="请输入企业基本户开户行"
              :disabled="this.forbidden"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="营业执照（正本）："
            style="position: relative"
            v-if="this.forbidden"
          >
            <span class="befor_1">*</span>
            <div style="display: flex; align-items: flex-end">
              <div
                style="width: 100px; height: 100px; margin-right: 20px"
                v-if="this.apply1 == 1"
              >
                <img
                  :src="this.filePath1"
                  alt
                  style="width: 100%; height: 100%"
                />
              </div>
              <el-upload
                class="upload-demo"
                :data="data1"
                action="api/newGuarantee.do?upLoadEnterpriseInfoFile"
                name="enterpriseInfoFile"
                :on-success="success1"
                accept=".jpg, .png"
                :show-file-list="false"
              >
                <el-button v-if="this.apply1 == 2">上传营业执照</el-button>
              </el-upload>
              <div
                v-if="this.apply1 == 2"
                style="
                  color: red;
                  font-size: 12px;
                  display: inline-block;
                  margin-left: 20px;
                  line-height: 40px;
                "
              >
                上传文件最小不低于50kb
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="开户行许可证："
            style="position: relative"
            v-if="this.forbidden"
          >
            <span class="befor_2">*</span>
            <div style="display: flex; align-items: flex-end">
              <div
                style="width: 100px; height: 100px; margin-right: 20px"
                v-if="this.apply2 == 1"
              >
                <img
                  :src="this.filePath2"
                  alt
                  style="width: 100%; height: 100%"
                />
              </div>
              <el-upload
                class="upload-demo"
                :data="data2"
                action="api/newGuarantee.do?upLoadEnterpriseInfoFile"
                name="enterpriseInfoFile"
                :on-success="success2"
                accept=".jpg, .png"
                :show-file-list="false"
              >
                <el-button v-if="this.apply2 == 2">上传开户许可证</el-button>
              </el-upload>
              <div
                v-if="this.apply2 == 2"
                style="
                  color: red;
                  font-size: 12px;
                  display: inline-block;
                  margin-left: 20px;
                  line-height: 40px;
                "
              >
                上传文件最小不低于50kb
              </div>
            </div>
          </el-form-item>
          <el-form-item v-if="this.buttonIsShow == false">
            <el-button type="primary" @click="submitForm('ruleForm')"
              >保存</el-button
            >
            <el-button @click="resetForm('ruleForm')">返回</el-button>
          </el-form-item>
          <el-form-item>
            <div style="color: red; text-align: center">
              如需修改企业信息，请联系平台客服
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
//这里导入其他文件（组件，工具js，第三方插件js，json文件，图片文件等等）
//import 《组件名称》 from '《组件路径》';
import Utils from "../components/util";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},

  // 组件名称
  name: "",

  // 这里存放数据
  data() {
    return {
      ruleForm: {
        firmName: "",
        socialCode: "",
        area: "",
        legal: "",
        id: "",
        account: "",
        bank: "",
      },
      rules: {
        legal: [{ required: true, message: "请输入法人名称", trigger: "blur" }],
        id: [
          { required: true, message: "请输入法人身份证", trigger: "blur" },
          { min: 18, max: 18, message: "身份证必须为18位", trigger: "blur" },
        ],
        area: [{ required: true, message: "请输入企业地址", trigger: "blur" }],
        account: [
          { required: true, message: "请输入银行基本户账号", trigger: "blur" },
        ],
        bank: [
          {
            required: true,
            message: "请输入企业基本户开户行",
            trigger: "blur",
          },
        ],
      },
      CAKeyEncStr: "",
      data1: {},
      data2: {},
      apply1: 2,
      apply2: 2,
      isShow: false,
      filePath1: "",
      filePath2: "",
      forbidden: false,
      buttonIsShow: false,
    };
  },

  // 监听属性 类似于data概念
  computed: {},

  // 监控data中的数据变化
  watch: {},

  // 方法集合
  methods: {
    submitForm(formName) {
      var type = window.sessionStorage.getItem("type");
      if (type == "CA") {
        var params = {
          enterpriseName: this.ruleForm.firmName,
          creditCode: this.ruleForm.socialCode,
          enterpriseAddress: this.ruleForm.area,
          legalPersonName: this.ruleForm.legal,
          cardCode: this.ruleForm.id,
          CAKeyEncStr: this.CAKeyEncStr,
          CAKeyId: this.ruleForm.firmName,
          from: 10,
          province: 340000,
          basicAccountCode: this.ruleForm.account,
          basicAccountName: this.ruleForm.bank,
        };
      } else {
        var params = {
          enterpriseName: this.ruleForm.firmName,
          creditCode: this.ruleForm.socialCode,
          enterpriseAddress: this.ruleForm.area,
          legalPersonName: this.ruleForm.legal,
          cardCode: this.ruleForm.id,
          CAKeyEncStr: window.sessionStorage.getItem("content"),
          CAKeyId: this.ruleForm.firmName,
          from: 10,
          province: 340000,
          basicAccountCode: this.ruleForm.account,
          basicAccountName: this.ruleForm.bank,
        };
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 成功
          this.axios({
            method: "GET",
            url: "/newGuarantee.do?saveEnterpriseInfo",
            params: params,
          }).then((res) => {
            if (res.data.status == 0) {
              this.$message({
                message: "保存成功,请上传营业执照与开户许可证",
                type: "success",
              });
              window.sessionStorage.setItem("com_address", this.ruleForm.area);
              window.sessionStorage.setItem(
                "legal_person",
                this.ruleForm.legal
              );
              window.sessionStorage.setItem("card_code", this.ruleForm.id);
              this.forbidden = true;
              this.buttonIsShow = true;
            } else {
              this.$message({
                message: res.data.statusInfo,
                type: "error",
              });
            }
          });
        } else {
          // 失败
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$router.push("/main");
      this.$refs[formName].resetFields();
    },

    // 获取企业信息
    getEnterpriseInformation() {
      var type = window.sessionStorage.getItem("type");
      if (type == "CA") {
        var params = {
          CAKeyId: this.ruleForm.firmName,
          from: 10,
          CAKeyEncStr: this.CAKeyEncStr,
        };
      } else {
        var params = {
          CAKeyId: this.ruleForm.firmName,
          from: 10,
          CAKeyEncStr: window.sessionStorage.getItem("content"),
        };
      }
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?existEnterpriseInfo",
        params: params,
      }).then((res) => {
        if (res.data.data.hasExist) {
          this.forbidden = true;
          this.buttonIsShow = true;
        } else {
          this.forbidden = false;
          this.buttonIsShow = false;
        }
        this.ruleForm.area = res.data.data.info.com_address;
        this.ruleForm.legal = res.data.data.info.legal_person;
        this.ruleForm.id = res.data.data.info.card_code;
        this.ruleForm.account = res.data.data.info.basic_account_code;
        this.ruleForm.bank = res.data.data.info.basic_account_name;
        this.filePath1 =
          "https://www.haneton.com/guarantee/" + res.data.data.bussinessPath;
        this.filePath2 =
          "https://www.haneton.com/guarantee/" + res.data.data.openPath;
      });
    },

    // 上传成功回调
    success1(response) {
      if (response.status == 0) {
        this.$message({
          message: "上传文件成功!",
          type: "success",
        });
        this.apply1 = 1;
        window.sessionStorage.setItem("apply1", 1);
        this.filePath1 = "https://www.haneton.com/guarantee/" + response.data;
        Utils.$emit("demo");
      } else {
        this.$message({
          message: "上传文件失败，" + response.statusInfo,
          type: "error",
        });
      }
    },
    success2(response) {
      if (response.status == 0) {
        this.$message({
          message: "上传文件成功!",
          type: "success",
        });
        this.apply2 = 1;
        window.sessionStorage.setItem("apply2", 1);
        this.filePath2 = "https://www.haneton.com/guarantee/" + response.data;
        Utils.$emit("demo");
      } else {
        this.$message({
          message: "上传文件失败，" + response.statusInfo,
          type: "error",
        });
      }
    },
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},

  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.apply1 = window.sessionStorage.getItem("apply1");
    this.apply2 = window.sessionStorage.getItem("apply2");
    this.ruleForm.firmName = window.sessionStorage.getItem("firmName");
    this.ruleForm.socialCode = window.sessionStorage.getItem("socialCode");
    this.CAKeyEncStr = window.sessionStorage.getItem("date");
    this.getEnterpriseInformation();
    this.data1 = {
      type: 1,
      CAKeyId: this.ruleForm.firmName,
      from: 10,
    };
    this.data2 = {
      type: 2,
      CAKeyId: this.ruleForm.firmName,
      from: 10,
    };
  },

  // 生命周期 - 创建之前
  beforeCreate() {},

  // 生命周期 - 挂载之前
  beforeMount() {},

  // 生命周期 - 更新之前
  beforeUpdate() {},

  // 生命周期 - 更新之后
  updated() {},

  // 生命周期 - 销毁之前
  beforeDestroy() {},

  // 生命周期 - 销毁完成
  destroyed() {},

  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  // keep-alive缓存离开之后触发
  deactivated() {},
};
</script>

<style scoped>
.message {
  height: 100%;
}
.demo-ruleForm {
  width: 50%;
}
.befor_1 {
  position: absolute;
  left: -150px;
  color: #f56c6c;
}
.befor_2 {
  position: absolute;
  left: -120px;
  color: #f56c6c;
}
</style>